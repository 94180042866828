// AddressBar.js

import React from 'react';
import '../Style/Addressbar.css';


const AddressBar = () => {
  return (
    <div className="address-bar-container">
        <div className="container">
        <div className="address-bar">
        <div className="left">
          <span className="icon size" ><i class="fa-solid fa-envelope"></i></span> 
          <span className="text">info@kaprojects.co.in</span> 
        </div>
        <div className="rights icons">
          <span className="icon"><i class="fa-solid fa-phone"></i></span>
          <span className="text ">9353990234</span> {/* Phone number */}
          <span className="icon"><i class="fa-brands fa-facebook"></i></span> 
          <span className="icon"><i class="fa-brands fa-linkedin"></i></span>
          <span className="icon"><i class="fa-brands fa-square-twitter"></i></span>
        </div>
      </div>
        </div>
  
    </div>
  );
};

export default AddressBar;
