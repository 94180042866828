import React from 'react';
import '../Style/Carrer.css'; // Import CSS file for styling
import AddressBar from '../Components/AddressBar';
import Navbar from '../Components/Navbar';
import CopyRightBar from '../Components/CopyRightBar';
import Arrow from '../Components/Arrow'; 
import Footer from '../Components/Footer';


const Carrer = () => {
  const openWhatsAppChat = () => {
    window.open('https://wa.me/1234567890', '_blank'); // Replace 1234567890 with your WhatsApp number
  };


    const handleSubmit = (event) => {
      event.preventDefault(); // Prevent default form submission
      // You can perform additional validation or submit the form data here
    };
  
    return (
      <div>
        {/* Address Bar */}
        <AddressBar/>
        {/* Navbar */}
        <Navbar/>
        {/* Header Section */}
        <div className="about-header">
          <div className="header-content">
            <h1>Career</h1>
          </div>
        </div>
        {/* Contact Information */}
        <div className="contact-info">
          <h2>For any career-related queries, please call:</h2>
          <p>(+91) 9353990234</p>
        </div>
        {/* Career Query Form */}
        <div className='p-4'>
        <div className="career-form">
          <h2>Send us your query</h2>
          <form onSubmit={handleSubmit}>
            {/* Full Name */}
            <input type="text" placeholder="Full Name" required />
            {/* Email Address */}
            <input type="email" placeholder="Email Address" required />
            {/* Phone Number */}
            <input type="tel" placeholder="Phone Number" required />
            {/* Title / Subject */}
            <input type="text" placeholder="Title / Subject" required />
            {/* Detailed Message */}
            <textarea placeholder="Detailed Message" required />
            {/* Upload Files */}
            <input type="file" accept=".jpg,.png,.gif,.pdf,.doc,.docx" />
            {/* Submit Button */}
            <button type="submit">Submit</button>
          </form>
        </div>
        </div>

        {/* Copy Rights */}
        <Footer/>
        <CopyRightBar/>
               {/* WhatsApp icon */}
         <Arrow/>
      </div>
    );
  }
  
  export default Carrer;

