import React from 'react';
import '../Style/Domestic.css'; 
import AddressBar from '../Components/AddressBar';
import Navbar from '../Components/Navbar';
import CopyRightBar from '../Components/CopyRightBar';
import Whatsup from '../assests/images/whatsup.png'; 
import Arrow from '../Components/Arrow';
import Footer from '../Components/Footer';
import Line from '../assests/images/line.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

const DomesticProjects = () => {
  const openWhatsAppChat = () => {
    window.open('https://wa.me/1234567890', '_blank'); // Replace 1234567890 with your WhatsApp number
  };

  return (
    <div>
      {/* Address Bar */}
      <AddressBar />
      {/* Navbar */}
      <Navbar />

      <div className="domestic-header">
        <div className="header-content">
          <h1>Domestic Projects</h1>
        </div>
      </div>

      {/* Services Section */}
      <div className="container service">
        <h2>Completed Projects In India</h2>
        <img src={Line} alt="line" className="clients-line" />

        {/* Service Details in List Format */}
        <div className="projects-list">
          <ul>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>Testing and commissioning of Siemens make relays at 3x660 MW NPGCL</h3>
                <p>Location:  Nabinagar, Delhi- C&S Electric</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of GE make relays at IOCL</h3>
                <p>Location: Paradip, Odisha-BHEL </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>Testing and commissioning Siemens and ABB make relays at Bhadrachalam-ITC</h3>
                <p>Location: Bhadrachalam</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 400/220 kV substation at Gadra-KPCL</h3>
                <p>Location: GADRA</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of 11kV switchear with numerical relays at Gadra-KPCL</h3>
                <p>Location: GADRA</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 400 kV substation at Ratnagiri-GE</h3>
                <p>Location: Rathangiri</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 132 kV substation at Bhubaneswar-OPTCL</h3>
                <p>Location: Bhubaneshwar</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 220/66/11 kV GIS substation at Koramangala-KEC</h3>
                <p>Location: Koramangala</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of Siemens make relays at 220/33kV substation at Govindapalli-OPTCL</h3>
                <p>Location: Govindapalli</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing of ABB make relays at Himachal Pradesh-SJVN</h3>
                <p>Location: Himachal Pradesh</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of Alstom make relays at GAIL</h3>
                <p>Location: Visakhapatnam- NS</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay testing and commissioning of switchgears at IVL Dhunseri Petrochemcial Industries-Prithivi Power Tech</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of RMU panels and numerical relays at 20MW Artia solar power plant at Penukonda</h3>
                <p>Location: Andhra Pradesh-Param</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>One bay CRP commissioning at 110kV substation at Hubli-One Energy</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 110/33kV substation at Gudadanal</h3>
                <p>Location: Penukonda-  Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 110/33kV substation at Lingsugur</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 110/33kV substation at Bapuram</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Auxiliary relay testing of Transformer CRP at 110/33kV Gurmatikal</h3>
                <p>Location: karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 110/33kV substation at Zalki</h3>
                <p>Location: karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 110/33kV substation at Nirlooti</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay testing at 110/33kV substation at Chitradurga</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 66/33kV Madanayakanahalli Substation</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 110/33kV substation at Abbigeri</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay testing at 110/33kV substation at Itnal</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>CRP testing and commissioning at 110/33kV Chikodi</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>

            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>CRP testing and commissioning at 110/33kV Galatga</h3>
                <p>Location: Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Transformer, Distance and Busbar protection relays testing in 220kV substation at Bableswar</h3>
                <p>Location: Bableswar</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintenance testing at 220kV Substation at Kazhugumalai  - Tamilnadu-Mini World </h3>
                <p>Location: Tamilnadu</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintenance testing at 220kV Substation at Therkupatti, Tamilnadu-Mini World</h3>
                <p>Location: Tamilnadu</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintenance testing at 220kV Substation at Khandke, amilnadu-Mini World</h3>
                <p>Location: Tamilnadu</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 20MW solar power plant switchyard and protection panels at Penukonda</h3>
                <p>Location: Andhra Pradesh-Artia</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of protection relays at 220kV substation at Yelahanka</h3>
                <p>Location: Karnataka-KPCL</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 400/220 kV GIS Substation at Hinjewadi</h3>
                <p>Location: Maharashtra-Wind world</p>
              </div>
            </li>

            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of Bus bar protection relays at 220kV Kesinga Substation at odisha – OPTCL</h3>
                <p>Location: odisha</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Power cable HV testing at 66/11kV substation</h3>
                <p>Location: Jindal city, Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 400 kV Substation</h3>
                <p>Location: Gumdipoondi, Tamilnadu-TTCL</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>220kV switchyard primary components testing</h3>
                <p>Location: Bihar</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Siemens make relays configuration, testing and commissioning at Neyveli PS-NTPS</h3>
                <p>Location: Neyveli</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 132/11 kV Substation at Odisa-Shree cements</h3>
                <p>Location: Odisha</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintenance of 220kv/33 kV Substation at Pune-Wind world India</h3>
                <p>Location: Pune</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>33/11 kV substation testing and commissioning </h3>
                <p>Location: Balguon, Rasulgarh, Infocity, New barang-Odisha-EMPTSPL</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Protection retrofitting works at various substation in Bihar-BSPTCL</h3>
                <p>Location: Bihar</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of ABB and Sifang relays at 110/33 kV</h3>
                <p>Location: Hosahanatti & Hoskate, Karnataka-KPTCL</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay testing and measurement devices calibration in BPA Platform(Offshore)Mumbai-ONGC-Total 546 relays</h3>
                <p>Location: Mumbai</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>220/33 kV GIS component And cable testing for BMRCL-L&T</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay setting & testing completed by Sangam sugar factory</h3>
                <p>Location: Belagavi, Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Installation ,testing and commissioning of various  motor protection relays at Rajasthan NPCIL</h3>
                <p>Location: Rajasthan</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>At 2*660MW SCTPS Suratgarh LT data concentrator (LTDC) Relay Signal and configuration in CHP for LTDC system total 400 Nos-C&S Electric Ltd</h3>
                <p>Location: UP</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintenance testing in 27MW solar power plant-Parbhani</h3>
                <p>Location: Maharashtra</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/11kV Substation </h3>
                <p>Location: Dubalgundi-KPTCL</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintenance testing in 36MW solar power plant</h3>
                <p>Location: Sirwar, Karnataka</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Cable testing at 66/11kV SS for Godrej commercial hub-SPTL</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of Siemens make relays-52 Nos in Panki, Kanpur-C&S Electric ltd</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>220 kV Kesinga Substation-Busbar protection relay configuration, testing and commissioning-OPTCL</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>AMC works-Breaker and Relay testing  of 10 MW Solar power plant</h3>
                <p>Location: Ramagundam, Telangana-NTPC</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>AMC work Breaker and Relay testing  of 70MW kunta, Kadiri, Andhra Pradesh-Tata Power</h3>
                <p>Location: Andhra Pradesh</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>HVAC Panel Installation and commissioning at Banalore-Voltas</h3>
                <p>Location: Banalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>ACB testing has been completed for NPCIL at Coimbatore-Schneider</h3>
                <p>Location: Coimbatore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>AMC works at 36.22 MW Raichur</h3>
                <p>Location: </p>
              </div>
            </li>
            <h2>Completed Projects (Factory Acceptance Test)-India</h2>
            <img src={Line} alt="line" className="clients-line" />
            <li>
           
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>ABB make relay configuration and testing at BHEL Factory</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Alstom make relay testing at Lotus power gear factory</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Schneider make relays factory testing at Coimbatore, NTPC</h3>
                <p>Location: Coimbatore,Tamil Nadu</p>
              </div>
            </li>
           

          </ul>
        </div>
      </div>

      {/* Footer */}
      <Footer />
      <CopyRightBar />

      {/* WhatsApp Icon */}
      <div className="whatsapp-icon" onClick={openWhatsAppChat}>
        <img src={Whatsup} alt="WhatsApp Icon" className="whatsapp-icon-img" />
      </div>

      {/* Arrow Icon */}
      <Arrow />
    </div>
  );
};

export default DomesticProjects;
