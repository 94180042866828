import React from 'react';
import '../Style/Projects.css'; 
import AddressBar from '../Components/AddressBar';
import Navbar from '../Components/Navbar';
import CopyRightBar from '../Components/CopyRightBar';
import Whatsup from '../assests/images/whatsup.png'; 
import Arrow from '../Components/Arrow';
import Footer from '../Components/Footer';
import Line from '../assests/images/line.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Projects = () => {
  const openWhatsAppChat = () => {
    window.open('https://wa.me/1234567890', '_blank'); // Replace 1234567890 with your WhatsApp number
  };

  return (
    <div>
      {/* Address Bar */}
      <AddressBar />
      {/* Navbar */}
      <Navbar />

      <div className="Projects-header">
        <div className="header-content">
          <h1>Projects</h1>
        </div>
      </div>

      {/* Services Section */}
      <div className="container service">
        <h2>Our Projects</h2>
        <img src={Line} alt="line" className="clients-line" />

        {/* Service Details in List Format */}
        <div className="projects-list">
          <ul>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>Testing Commissioning of  Simens make relays 3*660MW  NPGCL Nabinagar Substation at Delhi to Ms C& S Electric Ltd</h3>
                <p>Location: Delhi</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>D400 GE make relays commissioning @ IOCL Paradip MS BHEL</h3>
                <p>Location: Paradip</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 220 kV Siemens & ABB make relays at Bhadrachalam to Ms ITC </h3>
                <p>Location: Bhadrachalam</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 400kV/220 kV at Gadra MS KPCL</h3>
                <p>Location: GADRA</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>11KV Switchgear panel Numerical Protection Relays completed at Gadra Ms KPCL</h3>
                <p>Location: GADRA</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of 400 kV substation at Rathanagiri Ms GE</h3>
                <p>Location: Rathangiri</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning of  all relays 132 kV substation at  Bhubaneshwar Ms optcl</h3>
                <p>Location: Bhubaneshwar</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of  220/66/11 kv GIS Substion at Koramangala Ms KEC</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>All ABB make  Relay configuration at BHEL Factory Bangalore</h3>
                <p>Location: GADRA</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing Commissioning of Siemens make relays 220/33kv  Substation at Govinda palli  Ms OPTCL</h3>
                <p>Location: Malkangri -odisha</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing of ABB make relays at Himchalpradesg Ms SJVN</h3>
                <p>Location: Himchal pradesh</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and commissioning Alstom make relays Gail plant at Vizag Ms NS</h3>
                <p>Location: Vizag</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay configuration @nakawa,Bombo and Marble sites Make of relays Siemens at uganda Ms International energy technik (U) Ltd</h3>
                <p>Location: Uganda</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>All type of relays testing at IVL Dhunseri petrochem industries at Haldia Ms pruthivi power tech</h3>
                <p>Location: Haldia</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>20MW Artia Solar power plant RMU Panel and Numerical relays Testing commissioning done at Penukonda Ms Param renewable Energy</h3>
                <p>Location: Penukonda- Andra</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>110 kv  Line & CRP panel Testing completed at Hubli Ms one Energy</h3>
                <p>Location: Hubli</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Alstom make relays tested at Bangalore Lotus power gear Factory</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv at Gudadanal </h3>
                <p>Location: Gudadanal</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv  Substation at Lingsugur</h3>
                <p>Location: Lingsugur</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv  Substation at Bapuram</h3>
                <p>Location: Bapuram</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Auxilary relays & Transformer HV & LV Panel tested of 110/33 kv Gurmatikal</h3>
                <p>Location: Gurmatikal</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv at Zalki </h3>
                <p>Location: Zalki</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv at Kavithal wind power plant</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv  ss at Nirlooti</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv   Kavithal solar Energy</h3>
                <p>Location: </p>
              </div>
            </li>

            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Adisha make differential Relays tested at 110/33 kv SS Chitra durga</h3>
                <p>Location: Chitra durga</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing Commissioning of 66/33kv at Madanayakanahalli SS </h3>
                <p>Location: Chitra durga</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv  ABBIGERI Substation</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv Itnal Substion (CSC161)-(CSC326) - Relays commissioning</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Switchyard panel Testing commissioning of 110/33 kv at Chikodi </h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Switchyard panel Testing commissioning of 110/33 kv at Galatga</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>220kv Trafo 1 and 2 Nos 220kv Line 1 AND 2 distance & 12 Nos Busbar protection Tested at Bableswar</h3>
                <p>Location: Bableswar</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintanence at 220 kv Kazhugumalai Ms Wind world</h3>
                <p>Location: Kalugumalai</p>
              </div>
            </li>

            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintanence at 220 kv Therkupatti Ms Wind world</h3>
                <p>Location: Therkupatti</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintanence at 220kv Khandake substation Ms Wind world </h3>
                <p>Location: Mharastra</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>20MW Solar plant Switcyard testing & Relay testing has completed at Penukonda Ms Artia</h3>
                <p>Location: Penukonda- Andra</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay configuration and Settings & Testing commissionng @ 220kv SS  @ Yelahanka Ms KPCL</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 400/220 kv GIS Substation at Hinjewadi Ms Wind world</h3>
                <p>Location: Pune</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of Bus bar protection relays @ 220kv Kesinga Substation at odisha Ms OPTCL</h3>
                <p>Location: Odisha</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>66/11 KV cable testing done by Jindal city</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Tesitng commissioning of 400 kv Substation at Gumdipoondi Ms TTCL</h3>
                <p>Location: Chennai</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Factory testing of Schneider make relays at coimbatore Ms NTPC Tanda</h3>
                <p>Location: coimbatore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Switchyard Testing of 220 kv equipments @ Bihar </h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>All Siemens make relay configuration and Testing commissioning at Neyveli Ms NTPS</h3>
                <p>Location: Nayveli</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 132/11 kv Substation at odisa Ms Shree cement</h3>
                <p>Location: Odisha</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>33/11 KV various site  balguon , Rasulgarh,infocity ,New Barang , Testing commissioning has completed at odisha Ms EMPTSPL</h3>
                <p>Location: Odisha</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>All ZIV  make Relays retrofittings has completed at Bihar Ms BSPTCL</h3>
                <p>Location: UP</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>All ZIV  make Relays retrofittings has completed at Bihar Ms BSPTCL</h3>
                <p>Location: UP</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/33 kv  ABB & Sifang make relays at Hosahanatti & Hoskate Ms KPTCL</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Annual maintanence of 220kv/33 kv Substation at Pune Ms Wind world india</h3>
                <p>Location: Pune</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of220 kv  ABB & Sifang make relays at Hoskate Ms KPCL</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay testing and calibration various make relays totally 546 nos @ offshore Mumbai BPA platform Ms ONGSC</h3>
                <p>Location: Mumbai</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>66/11 KV cable testing done by Pestige Jindal city </h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>220/33 KV GIS And Cabiling BMRCL  Ms L&T</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Relay setting & testing completed by Sangam sugar factory at Belagavi</h3>
                <p>Location: Belagavi</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Installation ,Commissioning & testing universal motor protection relays @ Rajasthan Ms NPCIL</h3>
                <p>Location: Jaipur</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>2*660MW SCTPS suratgarh LT data concentrator (LTDC) Relay Signal and configuration at CHP for LTDC system total no 400 nos Ms C&S Electric Ltd</h3>
                <p>Location: Rajasthan</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>27 MW Solar plant   Annual maintance at Parbhani maharastra</h3>
                <p>Location: Maharastra</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing commissioning of 110/11kv Substation KPTCL at Dubalgundi</h3>
                <p>Location: Humnabad</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>36 MWp Solar plant   Annual maintance at Sirwar Karnataka Ms HREPL</h3>
                <p>Location: Sirwar</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>66/11 kv cable testing done by Godrej commercial hub Ms SPTL </h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>1*660 MW panki kanpur Siemens make relays 52 nos Testing and commissioning Ms C&S Electric ltd</h3>
                <p>Location: </p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Cable schdule & Estimation done by all TR3 Nigeria Substions</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing & Commissioning of 330/132/33 kv Swichyard & relay testing and commissioning -charging has completed at Damatur Substation at Nigeria</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing & Commissioning of 1*60MVA and 132/33 Kv and 1*150MVA ,  330/132/33 kv  transformers Testing Swichyard & relay testing and commissioning  DAMA Substation  at Nigeria</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing & Commissioning of 330/132/33 kv Swichyard & relay testing and commissioning -charging has completed at yola Substation at Nigeria</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing Commissioning of 220KV level  Switchyard and Relay testing at Cameroon - Project is going on</h3>
                <p>Location: Cameroon</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of 330/132/33 kv Substation at  New BAUCHI Switchayard and relay testing is going on</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>1*660 MW panki kanpur Siemens make relays 60 nos Testing and commissioning  is going on Ms C&S Electric ltd</h3>
                <p>Location: Kanpur</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of 330/132/33 kv Substation at Maduguri Switchayard and relay testing is going on</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>ACB testing has been completed by NPCIL at Coimbatore Ms Schneider</h3>
                <p>Location: coimbatore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of132/33 kv AJAH mobile  Substation at  Nigeria</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of132/33 kv  JEBBA mobile  Substation at  Nigeria</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>HVAC Panel Installation and commissioning at Banalore Ms Voltas</h3>
                <p>Location: Bangalore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>AMC work Breaker and Relay testing  of 70MW at NP kunta, Kadiri  MS Tata Solar</h3>
                <p>Location: Kadri</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Factory Panel testing of Schneider make relays at coimbatore Ms NTPC Tanda</h3>
                <p>Location: coimbatore</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>AMC work Breaker and Relay testing  of 10 MW at  Ramagundam Ms NTPC</h3>
                <p>Location: Ramagundam</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of 132/33 kv  BIU Substation  project is going on at  Nigeria</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>220 KV Kesinga SubstationBusbar relay Cofiguration & commissioning Ms optcl</h3>
                <p>Location: Odisha</p>
              </div>
            </li>


          </ul>
        </div>
      </div>

      {/* Footer */}
      <Footer />
      <CopyRightBar />

      {/* WhatsApp Icon */}
      <div className="whatsapp-icon" onClick={openWhatsAppChat}>
        <img src={Whatsup} alt="WhatsApp Icon" className="whatsapp-icon-img" />
      </div>

      {/* Arrow Icon */}
      <Arrow />
    </div>
  );
};

export default Projects;
