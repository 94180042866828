import React from 'react';
import '../Style/Footer.css'; // Import CSS file for styling
import FooterLogo from '../assests/images/Logo2.jpg'
import CustomerBg from '../assests/images/FooterBG.jpg'
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <div className=' ' style={{ backgroundImage: `url(${CustomerBg})` }}>
    <footer className="footer container" >
        <div className='row'>
        <div className='col-lg-4 col-md-4 col-sm-12'>
        <div className="footer__left">
        {/* Logo */}
        <img src={FooterLogo} alt="Company Logo" className="footer__logo" />
        {/* Company Address */}
        <p className='pt-2'>K A Projects sustainable success is a blend of sensitive, selective and speedy approach towards our business and environment. K A Projects was founded in December 2019, by K A Projects. Restructuring of the management has been done in the year 2024 to include few eminent technocrats from the energy sector.</p>
      </div>
        </div>
        <div className='col-lg-4 col-md-4 col-sm-12'>
               
      <div className="footer__center pt-3">
        {/* Quick Links */}
        <h3>Quick Links</h3>
        <ul className="footer__links">
          <li><a> <Link  to='/'>
              Home</Link></a></li>
          <li><a> <Link  to='/about'>
              About</Link></a></li>
          <li><a><Link  to='/service'>
              Services</Link></a></li>
          <li><a><Link  to='/career'>
              Career</Link></a></li>
          <li><a><Link  to='/contact'>
              Contact Us</Link></a></li>
        </ul>
      </div>
            </div>
            <div className='col-lg-2 col-md-4 col-sm-12 pt-3'>
            <div className="footer__right">
        {/* Social Media Icons */}
        <h3>Contact Us</h3>
        <span className="icon"><i class="fa-solid fa-phone"></i> Cell: 9353990234<br/></span> {/* Phone icon */}
        <span className="text ">Email : <br/>vanitha.satish@kaprojects.co.in<br/>prabika.p@kaprojects.co.in</span> {/* Phone number */}

      
       


      </div>
            </div>
            <div className='col-lg-2 col-md-4 col-sm-12 pt-3'>
            <h3>Social Media Links</h3>
   
   <div className="right icons">
     <span className="icon"><i class="fa-brands fa-facebook"></i></span> 
     <span className="icon"><i class="fa-brands fa-linkedin"></i></span>
     <span className="icon"><i class="fa-brands fa-square-twitter"></i></span>
   </div>
        </div>

        </div>

  
    </footer>
    </div>

  );
}

export default Footer;
