import React, { useEffect, useState } from 'react';
import Whatsup from '../assests/images/whatsup.png' 

const Arrow = () => {
    const openWhatsAppChat = () => {
        window.open('https://wa.me/9353990234', '_blank'); // Replace 1234567890 with your WhatsApp number
      };
       // Scroll to top function
       const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
  
    // State to control visibility of the up arrow
    const [showScroll, setShowScroll] = useState(false);
    
    // Function to handle scroll event
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, [showScroll]);
    
  return (
    <div>
              {/* Up arrow */}
              <div className={`up-arrow ${showScroll ? 'show' : 'hide'}`} onClick={scrollToTop}>
                <i className="fas fa-arrow-up"></i>
            </div>
          {/* WhatsApp icon */}
      <div className="whatsapp-icon" onClick={openWhatsAppChat}>
        <img
          src={Whatsup}// Replace this with your WhatsApp icon image
          alt="WhatsApp Icon"
          className="whatsapp-icon-img"
        />
      </div>
    </div>
  )
}

export default Arrow