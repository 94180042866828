import React from 'react';
import '../Style/Service.css'; // Import CSS file for styling
import AddressBar from  '../Components/AddressBar';
import Navbar from '../Components/Navbar';
import CopyRightBar from '../Components/CopyRightBar';
import service1 from '../assests/images/services1.jpg' 
import service2 from '../assests/images/services2.jpg' 
import service3 from '../assests/images/services3.jpg' 
import service4 from '../assests/images/services4.jpg' 
import service5 from '../assests/images/services5.jpg' 
import service6 from '../assests/images/services6.png'
import service7 from '../assests/images/services7.jpg'
import Whatsup from '../assests/images/whatsup.png' 
import Arrow from '../Components/Arrow';
import Footer from '../Components/Footer';
import Line from '../assests/images/line.png'
import OurClients from '../assests/images/ourClients.jpg'


const Service = () => {
  const openWhatsAppChat = () => {
    window.open('https://wa.me/1234567890', '_blank'); // Replace 1234567890 with your WhatsApp number
  };


  return (
    <div>
      {/* Address Bar start */}
      <AddressBar/>
      {/* Navbar Start */}
      <Navbar/>
      <div className="Service-header">
        <div className="header-content">
          <h1>Services</h1>
        </div>
      </div>
       {/* Service Methods Start */}
       <div className='container service'>
        <h2>Our Services</h2>
        <img src={Line} alt='line' className='clients-line'></img>
         <div className='row service-content'>
            <div className='service-card col-lg-4 col-md-6 col-sm-12'>
            <img src={service1} alt='service-image img1'></img>
            <h3>ENGINEERING DESIGN</h3>
            <p>Engineering design is a fundamental aspect of the engineering process, encompassing the creation and development of products, systems, or structures to meet specific requirements and solve problems efficiently. It involves a systematic approach that integrates scientific principles, mathematical analysis, creativity, and practical considerations to produce innovative and functional solutions.</p>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
            <img src={service2} alt='service-image img1'></img>
            <h3>OWNERS ENGINEER</h3>
            <p>An Owner's Engineer, often abbreviated as OE, is a professional or a team of professionals hired by an entity or an individual (the owner) to represent their interests throughout the lifecycle of a project. The role of an Owner's Engineer is multifaceted, spanning various phases from project conception to completion. Their primary objective is to ensure that the owner's interests are protected, and the project is executed successfully, meeting predefined objectives in terms of quality, cost, schedule, and safety. Here are the key responsibilities and functions typically associated with an Owner's Engineer:</p>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
            <img src={service3} alt='service-image img1'></img>
            <h3>PROJECT MANAGEMENT</h3>
            <p>Project management is the process of planning, organizing, executing, controlling, and closing projects efficiently and effectively to achieve specific goals and objectives within defined constraints such as time, cost, scope, and quality. It is a critical discipline applied across various industries and sectors to successfully deliver projects of varying complexity and scale. Here are the key components and processes involved in project management</p>
            </div>
         </div>
         <div className='row service-content'>
            <div className='col-lg-4 col-md-6 col-sm-12'>
            <img src={service4} alt='service-image img1'></img>
            <h3>CONSTRUCTION MANAGEMENT</h3>
            <p>Construction management is the practice of overseeing the planning, design, and construction of a project from inception to completion. It involves coordinating various stakeholders, resources, and activities to ensure that the project is delivered on time, within budget, and according to quality standards and specifications. Construction managers play a crucial role in facilitating communication, managing risks, and resolving issues to ensure the successful execution of construction projects</p>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
            <img src={service5} alt='service-image img1'></img>
            <h3>TESTING AND COMMISSIONING</h3>
            <p>Testing and commissioning are crucial phases in the lifecycle of a project, especially in industries such as construction, engineering, manufacturing, and infrastructure development. These phases ensure that systems, equipment, or facilities are operational, compliant with standards, and ready for use.</p>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
            <img src={service6} alt='service-image img1'></img>
            <h3>TECHNICAL SERVICES</h3>
            <p>Technical services encompass a broad range of specialized services provided by professionals with expertise in various technical fields. These services are typically offered to industries, businesses, governments, and individuals to support their operations, projects, and initiatives. Technical services play a crucial role in facilitating innovation, problem-solving, and decision-making across different sectors. </p>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
            <img src={service7} alt='service-image img1'></img>
            <h3>SPECIALIZED SERVICES</h3>
            <p>Specialized services refer to a variety of professional services that require specific expertise, skills, or knowledge in niche areas. These services are often tailored to meet the unique needs and requirements of clients across various industries. Specialized service providers offer targeted solutions to address specific challenges, tasks, or projects </p>
            </div>
         </div>
       </div>
       <div className='new-services maps container'>
       <h2>Testing and Commissioning Services </h2>
       <h5 className=''>Expert Testing and Commissioning for Primary and Secondary Substations</h5>
       <p className='service-sub-para'>At  K A PROJECTS, we specialize in providing top-tier electrical testing and commissioning services, specifically tailored for primary and secondary substations. Our experienced team ensures that your substations operate reliably, efficiently, and safely, meeting all industry standards and regulatory requirements.</p>
       <h2 className=''>Our Substation Services Include:</h2>
       <h5 className=''>1. Pre-Commissioning Testing</h5>
       <p><span>Insulation Resistance Testing:</span> Evaluate the insulation quality of transformers, switchgear, and cables to prevent failures.</p>
       <p><span>Circuit Breaker Testing: </span> Ensure the proper functioning of circuit breakers to protect your substation from faults.</p>
       <p><span>High-Potential (HiPot) Testing: </span> Verify the dielectric strength of substation equipment to prevent electrical breakdowns.</p>
       <h5 className=''>2. Commissioning Services</h5>
       <p><span>Transformer Testing:</span> Perform ratio, polarity, and winding resistance tests to confirm the correct installation and operation of transformers</p>
       <p><span>Protection and Control Systems Testing: </span>  Validate the functionality of protection relays, control circuits, and automation systems to ensure system stability.</p>
       <p><span>SCADA System Commissioning: </span>  Integrate and test SCADA systems for real-time monitoring and control of substation operations.</p>
       <h5 className=''>3. Post-Commissioning Testing</h5>
       <p><span>Partial Discharge Testing:</span> Detect and address potential faults in substation equipment before they lead to failures.</p>
       <p><span>Thermal Imaging:</span>  Identify and mitigate hotspots in electrical connections and components to prevent downtime.</p>
       <p><span>Power Quality Analysis </span>   Ensure optimal power quality to maintain the performance and longevity of substation equipment.</p>
       <h5 className=''>Testing and commissioning of Substation equipment</h5>
       <p><i class="fas fa-check"></i>Power Transformer Testing</p>
       <p><i class="fas fa-check"></i>Circuit Breaker Testing</p>
       <p><i class="fas fa-check"></i>Current , Voltage Transformer Testing</p>
       <p><i class="fas fa-check"></i>Isolator , Earth Switch Testing</p>
       <p><i class="fas fa-check"></i>Surge Arrester Testing</p>
       <h5 className=''>Configuration, Testing & Commissioning of Numerical Relays</h5>
       <p><i class="fas fa-check"></i>Micom Relays Testing</p>
       <p><i class="fas fa-check"></i>Circuit Breaker Testing</p>
       <p><i class="fas fa-check"></i>ABB Make Relays Testing</p>
       <p><i class="fas fa-check"></i>Siemens Make Relay Testing</p>
       <p><i class="fas fa-check"></i>Other Make Relays</p>
       <h5 className=''>Configuration, Testing & Commissioning of Substation Automation Systems</h5>
       <p><i class="fas fa-check"></i>GE Make Scada</p>
       <p><i class="fas fa-check"></i>ABB and Hitachi Make Scada</p>
       <p><i class="fas fa-check"></i>Sifang Make Scada</p>
       <p><i class="fas fa-check"></i>System Integration</p>
       <p><i class="fas fa-check"></i>System Upgradation</p>
       <h5 className=''>Testing equipments and tools</h5>
       <p>1. Omicron CMC 356</p>
       <p>2. Megger SMRT46</p>
       <p>3. Doble F6150</p>
       <p>4. CPC 100</p>
       </div>
              {/* Our Clients  Section Start */}
              <div className='maps container'>
            <h2>Our Clients</h2>
            <img src={Line} alt='line' className='clients-line'></img>
            <div className='row distance'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <p>1.	L & T CONSTRUCTION</p>
            <p>2.	L & T AUTOMATION SYSTEM</p>
            <p>3.	TATA PROJECTS</p>
            <p>4.	TATA POWER SOLAR  SYSTEMS LIMITED</p>
            <p>5.	ORIONA SOLAR </p>
            <p>6.	C&S ELECTIRC LTD</p>
            <p>7.	MBH POWER LTD</p>
            <p>8.	BHEL</p>
            <p>9.	OPTCL</p>

              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<img src={OurClients} alt='line' ></img>
</div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
            <p>10.	VOLTAS LIMITED</p>
            <p>11.	SHOBA PROJECTS</p>
            <p>12.	WIND POWER PROJECTS</p>
            <p>13.	JYOTITECH INDUSTRIES</p>
            <p>14.	SIFANG AUTOMATION</p>
            <p>15.	NR ELECTRIC LTD</p>
            <p>16.	Wind World India Ltd</p>
            <p>17.  International Energy Technik LTD</p>

</div>

            </div>
          
           

        </div>
      {/* Copy Rights Starts */}
      <Footer/>
      <CopyRightBar/>
       {/* WhatsApp icon */}
       <div className="whatsapp-icon" onClick={openWhatsAppChat}>
        <img
          src={Whatsup}// Replace this with your WhatsApp icon image
          alt="WhatsApp Icon"
          className="whatsapp-icon-img"
        />
      </div>
      {/* Arrow and Whatsup Icon Start */}
      <Arrow/>
    </div>
  );
}

export default Service;
