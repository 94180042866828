import React from 'react';
import '../Style/About.css'; // Import CSS file for styling
import AddressBar from  '../Components/AddressBar';
import Navbar from '../Components/Navbar';
import CopyRightBar from '../Components/CopyRightBar';
import Footer from '../Components/Footer';
import OurClients from '../assests/images/ourClients.jpg'
import Line from '../assests/images/line.png'
import Arrow from '../Components/Arrow'; 
import Tool from '../assests/images/tool.jpg'



const About = () => {
      
  const openWhatsAppChat = () => {
    window.open('https://wa.me/1234567890', '_blank'); // Replace 1234567890 with your WhatsApp number
  };


  return (
    <div>
      {/* Address Bar start */}
      <AddressBar/>
      {/* Navbar Start */}
      <Navbar/>
      <div className="about-header">
        <div className="header-content">
          <h1>About</h1>
        </div>
      </div>
      <div className='about-say container'>
        <h2 >About K A Projects</h2>
        <p>K A Projects sustainable success is a blend of sensitive, selective and speedy approach towards our business and environment. K A Projects was founded in December 2019, by K A Projects. Restructuring of the management has been done in the year 2024 to include few eminent technocrats from the energy sector.</p>
      </div>
        {/* About Content  Section Start */}
        <div className='map container'>
            <h2>TESTING AND COMMISSIONING</h2>
            <img src={Line} alt='line' className='clients-line'></img>
            <p className='pt-4 about-para'>K A Projects Engineers has extensive experience in the testing of electrical systems and equipment in electrical utility facilities, including all types of generating stations, substations (e.g. receiving, distribution, etc.),
Our group of highly qualified engineers has years of experience and expertise in the test and start-up, and in general, in the commissioning of electrical, Engineers has also trained Operation and Maintenance (O&M) personnel during test and start-up activities
 K A Projects staff includes a group of seasoned engineering staff for design, as well as a highly recognized group of field test engineers and technicians, competent to perform from a whole unit commissioning to a protective relay setting test.
Among some of the facilities or systems we provide test and start-up services include: 
</p>

            <div className='map-img'>
            </div>
        </div>
          <div className='container'>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
          <div className='Testing-methods p-5'>
            <h4>TESTING AND COMMISSIONING</h4>
            <img src={Line} alt='line' className='clients-line'></img>
            <p className='pt-4'><i class="fas fa-check"></i>Low, Medium and High Voltage Power Systems</p>
            <p><i class="fas fa-check"></i>Substations (up to 765 kV AC/DC)</p>
            <p><i class="fas fa-check"></i>
	Generators or Generating Stations (e.g. Hydro, Solar, Gas, etc.)</p>
            <p><i class="fas fa-check"></i>
	Automation/Modernization Systems</p>
            <p><i class="fas fa-check"></i>
	SCADA</p>
            <p><i class="fas fa-check"></i>
	Testing & Commissioning Services for Power Equipment.</p>
            <p><i class="fas fa-check"></i>
	Protection & Automation System </p>
            <p><i class="fas fa-check"></i>
	 Design and System Studies </p>
            <p><i class="fas fa-check"></i>
	 Retrofitting and Refurbishment Activities </p>
            <p><i class="fas fa-check"></i>
	 Annual and maintenance and testing Consultancy Services  Training. 
</p>
            <p>Transformer Testing</p>
            <p><i class="fas fa-check"></i>	Trandormer OIL Testing like, BDV, PPM, DGA.</p>
            <div className='map-img'>
            </div>
        </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 back-ground-color'>
          <div className='Testing-methods p-5'>
            <h4>Testing, Maintenance, Repairs & Retrofits</h4>
            <img src={Line} alt='line' className='clients-line'></img>
            <p className='pt-4'><i class="fas fa-check"></i>
	Grounding System Testing</p>
            <p><i class="fas fa-check"></i>
	Protective Relay Calibration</p>
            <p><i class="fas fa-check"></i>
	Circuit Breaker Testing, Maintenance and Retrofits (up to 765 kV)</p>
            <p><i class="fas fa-check"></i>
	Transformer Testing/Analysis & Repair</p>
            <p><i class="fas fa-check"></i>
	Switchgear & Motor Control Maintenance/Retrofits</p>
            <p><i class="fas fa-check"></i>
	Generator /Exciter Test & Maintenance</p>
            <p><i class="fas fa-check"></i>
	Variable Speed Drives Test and Repair/Retrofit </p>
            <p><i class="fas fa-check"></i>
	Variable Speed Drives Test and Repair/Retrofit </p>
            <div className='map-img'>
            </div>
        </div>
            </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 back-ground-color'>
          <div className='Testing-methods p-5'>
            <h4>Preventative Maintenance & Repair</h4>
            <img src={Line} alt='line' className='clients-line'></img>
            <p className='pt-4'><i class="fas fa-check"></i>
	Primary Current Injection Testing</p>
            <p><i class="fas fa-check"></i>
	AC/DC Hi-Potential Testing</p>
            <p><i class="fas fa-check"></i>
	Power Factor Insulation Testing</p>
            <p><i class="fas fa-check"></i>
	Tan Delta Tip-up Testing</p>
            <p><i class="fas fa-check"></i>
	Oil & Gas Analysis</p>
            <p><i class="fas fa-check"></i>
	Switchgear Modifications, Upgrades and Repair</p>
            <p><i class="fas fa-check"></i>
	AC/DC Hi-Potential Testing</p>
            <p><i class="fas fa-check"></i>
	Oil and Gas Analysis</p>
            <p><i class="fas fa-check"></i>
	Inrared Analysis </p>
            <p><i class="fas fa-check"></i>
	Breaker Retrofits, Reconditioning and Repair</p>
            <div className='map-img'>
            </div>
        </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 '>
          <div className='Testing-methods p-5'>
            <h4>Commissioning /Test & Start-up Services</h4>
            <img src={Line} alt='line' className='clients-line'></img>
            <p className='pt-4'><i class="fas fa-check"></i>
	Monthly Inspection of Substations</p>
            <p><i class="fas fa-check"></i>
	Total Combustible Gases Checked</p>
            <p><i class="fas fa-check"></i>
	Transformer Inspections</p>
            <p><i class="fas fa-check"></i>
	Annual Testing of Incoming Line Equipment</p>
            <p><i class="fas fa-check"></i>
	Substation Battery Inspections & Maintenance</p>
            <p><i class="fas fa-check"></i>
	Scheduled Outage Services</p>
            <p><i class="fas fa-check"></i>
	SF6 Gas Handling Services</p>
            <p><i class="fas fa-check"></i>
	Infrared Analysis </p>
            <p><i class="fas fa-check"></i>
	Recloser Maintenance </p>

            <div className='map-img'>
            </div>
        </div>
            </div>
        </div>
          </div>
               {/* Tool Details */}
      <div className='container tool-content'>
        <h3>Testing equipments Tools</h3>
        <img src={Line} alt='line' className='tool-line'></img>
          <div className='row tool-list'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
               <p><i class="fas fa-check"></i>
 Omicron </p>
               <p><i class="fas fa-check"></i>
Doble  </p>
               <p><i class="fas fa-check"></i>
Conventional injection Kits </p>
               <p><i class="fas fa-check"></i>
Breaker Timer Kit θ CB Contact Resistance Kit </p>
               <p><i class="fas fa-check"></i>
Earth Resistance Kit θ</p>
               <p><i class="fas fa-check"></i>
Loading transformer θ Insulation Testers </p>
               <p><i class="fas fa-check"></i>
Variac Single & three phase </p>
               <p><i class="fas fa-check"></i>
DC Power pack θ Tong testers 
Phase Sequence Meter θ Galvanometer  Multimeters
</p>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12'>
              <img src={Tool} alt='tool'/>
            </div>
          </div>
        </div>
      <div className='pb-2'> 
      <div className='vision-section container'>
        <div className='row'>
           <div className='col-lg-6 col-md-12 col-sm-12'>
           <h2>Our Vision</h2>
        <p>Our vision is to lead with innovation and sustainability, powering progress and prosperity for all.</p>
           </div>
           <div className='background-color col-lg-6 col-md-12 col-sm-12'>
           <h3 className='our-mission-title'>Our Mission</h3>
           <p className='para'>
Our mission is to provide exceptional electrical solutions, empower communities, and drive sustainable growth through innovation and integrity.</p>
           </div>
        </div>
       
      </div>
      </div>
 
          {/* Our Clients  Section Start */}
          <div className='map container'>
            <h2>Our Clients</h2>
            <img src={Line} alt='line' className='clients-line'></img>
            <div className='row distance'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <p>1.	L & T CONSTRUCTION</p>
            <p>2.	L & T AUTOMATION SYSTEM</p>
            <p>3.	TATA PROJECTS</p>
            <p>4.	TATA POWER SOLAR  SYSTEMS LIMITED</p>
            <p>5.	ORIONA SOLAR </p>
            <p>6.	C&S ELECTIRC LTD</p>
            <p>7.	MBH POWER LTD</p>
            <p>8.	BHEL</p>
            <p>9.	OPTCL</p>

              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<img src={OurClients} alt='line' ></img>
</div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
            <p>10.	VOLTAS LIMITED</p>
            <p>11.	SHOBA PROJECTS</p>
            <p>12.	WIND POWER PROJECTS</p>
            <p>13.	JYOTITECH INDUSTRIES</p>
            <p>14.	SIFANG AUTOMATION</p>
            <p>15.	NR ELECTRIC LTD</p>
            <p>16.	Wind World India Ltd</p>
            <p>17.  International Energy Technik LTD</p>

</div>

            </div>
           
           

        </div>
  

      {/* Copy Rights Starts */}
      <Footer/>
      <CopyRightBar/>
         {/* WhatsApp icon */}
         <Arrow/>
         
    </div>
  );
}

export default About;
