import React from 'react'

const CopyRightBar = () => {
  return (
    <div>
     {/* Copy Rights reserve */}
    <div className='copy-rights'>
      <p>Copyright © 2024 - K A Projects - Powered by <a href='https://techslideitsolutions.com/'> TechSlide ITS</a></p>
    </div>
    </div>
  )
}

export default CopyRightBar