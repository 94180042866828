import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import HeaderImg1 from '../assests/images/welcome.jpg';
import HeaderImg2 from '../assests/images/headerImg1.png';
import HeaderImg3 from '../assests/images/powerstation.png';
import HeaderImg4 from '../assests/images/Miantanance.jpeg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Style/Header.css'; // Import CSS file for header styling

const Header = () => {
  AOS.init();



  return (
    <div>
      <Carousel interval={3000} pause={false}>
        <Carousel.Item>
          <div className="carousel-content">
            <img
              className="d-block w-100"
              src={HeaderImg1}
              alt="First slide"
            />
          <div className="centered-content fade-in">
  <h1 data-aos="fade-right" data-aos-duration="1000">WELCOME to K A Projects</h1>
  <p data-aos="fade-left" data-aos-duration="800">We are a global engineering consultants helping companies to grow through vision and imagination.</p>
</div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-content">
            <img
              className="d-block w-100"
              src={HeaderImg2}
              alt="Second slide"
            />
            <div className="centered-content fade-in">
              <h1 data-aos="fade-right" data-aos-duration="1000">Energy Auditing</h1>
              <p  data-aos="fade-left" data-aos-duration="800">We have BEE Certified Energy Auditors for undertaking energy auditing.</p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-content">
            <img
              className="d-block w-100"
              src={HeaderImg3}
              alt="Third slide"
            />
            <div className="centered-content fade-in">
              <h1 data-aos="fade-right" data-aos-duration="1000">Preventive / Operation Maintenance</h1>
              <p data-aos="fade-left" data-aos-duration="800">Our team provides the expertise needed to keep your power system safe, efficient, reliable and up-to-date.</p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="carousel-content">
            <img
              className="d-block w-100"
              src={HeaderImg4}
              alt="Fourth slide"
            />
            <div className="centered-content fade-in">
              <h1 data-aos="fade-right" data-aos-duration="1000">Testing and Commissioning Services</h1>
              <p data-aos="fade-left" data-aos-duration="800">We offer testing and commissioning services for power equipments. Testing and commissioning services is the key activity in any field where the new system getting </p>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Header;
