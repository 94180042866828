import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Screeen/Home';
import About from './Screeen/About';
import Service from './Screeen/Service';
import DomesticProjects from './Screeen/DomesticProjects';
import InternationalProjects from './Screeen/InternationalProjects';
import  OngoingPropjects from './Screeen/OngoingPropjects';
import Projects from './Screeen/Projects'
import Carrer from './Screeen/Carrer';
import ContactUs from './Screeen/ContactUs';

function App() {
  return (
    <div className="App"> 
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/about" element={<About/>} />
    <Route path="/projects" element={<Projects/>} />
    <Route path="/domestic-projects" element={<DomesticProjects/>} />
    <Route path="/international-projects" element={<InternationalProjects/>} />
    <Route path="/ongoing-projects" element={<OngoingPropjects/>} />
    <Route path="/service" element={<Service/>} />
    <Route path="/career" element={<Carrer/>} />
    <Route path="/contact" element={<ContactUs/>} />
    </Routes>
    </BrowserRouter>
   
    </div>
  );
}

export default App;
