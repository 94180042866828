import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import Navbar from '../Components/Navbar';
import AddressBar from  '../Components/AddressBar'
import '../Style/Home.css'; 
import Miantanance from '../assests/images/Miantanance.jpeg'
import Client1 from '../assests/images/clients1.jpg'
import Client2 from '../assests/images/clients2.jpg'
import Client3 from '../assests/images/client4.jpg'
import Client4 from '../assests/images/clients5.jpg'
import Client5 from '../assests/images/clients6.jpg'
import Client6 from '../assests/images/clients7.jpg'
import Client7 from '../assests/images/clients8.jpg'
import Client8 from '../assests/images/clients9.jpg'
import Client9 from '../assests/images/clients10.jpg'
import Client10 from '../assests/images/clients11.jpg'
import Client11 from '../assests/images/clients12.jpg'
import OurClients from '../assests/images/ourClients.jpg'
import Footer from '../Components/Footer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CopyRightBar from '../Components/CopyRightBar'
import Arrow from '../Components/Arrow';
import service1 from '../assests/images/services1.jpg' 
import service2 from '../assests/images/services2.jpg' 
import service3 from '../assests/images/services3.jpg' 
import service4 from '../assests/images/services4.jpg' 
import service5 from '../assests/images/services5.jpg' 
import service6 from '../assests/images/services6.png'
import service7 from '../assests/images/services7.jpg' 
import Line from '../assests/images/line.png'
import CustomerReviewImage from '../assests/images/customerImage.jpg'
const Home = () => {


       // Settings for the carousel
       const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 3000, // Set the speed of automatic sliding (in milliseconds)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    const settingsClient = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 3000, // Set the speed of automatic sliding (in milliseconds)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    const settingss = {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 2000, // Set the speed of automatic sliding (in milliseconds)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    const settingsss = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 2000, // Set the speed of automatic sliding (in milliseconds)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    
  const openWhatsAppChat = () => {
    window.open('https://wa.me/1234567890', '_blank'); // Replace 1234567890 with your WhatsApp number
  };
   // Scroll to top function
   const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

// State to control visibility of the up arrow
const [showScroll, setShowScroll] = useState(false);

// Function to handle scroll event
const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
    }
};
const [isOpen, setIsOpen] = useState(false);

const toggleNavbar = () => {
  setIsOpen(!isOpen);
};

useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
}, [showScroll]);



  return (
    <div>
        {/* Address Bar start */}
        <AddressBar/>
        <Navbar/>
        {/* Header Start */}
         <Header />
      <div className='container pt-5 pb-5'>
      <div className='row'> 
     <div className='col-lg-6 col-md-12 col-sm-12'>
     <div className="column left-column offer-title">
          <h2 data-aos="fade-right" data-aos-duration="1000">We Offer High Quality Service For Our Customers</h2>
          <p data-aos="fade-right" data-aos-duration="1000">K A Projects sustainable success is a blend of sensitive, selective and speedy approach towards our business and environment. K A Projects was founded in December 2019, by K A Projects. Restructuring of the management has been done in the year 2024 to include few eminent technocrats from the energy sector.</p>
        </div>
     </div>
     <div className='col-lg-6 col-md-12 col-sm-12'>
     <div className="column right-column">
            <img src={Miantanance} alt="header img 1" />
        </div>
</div>
    </div>
      </div>
      {/* Our service section Start */}
      <div className='service-section container'>
        <div className='service-title'>
      <h2>Our Services</h2>
      <img src={Line} alt='line' className='service-line'></img>
      <Slider {...settingsss}>
      <div className='service-sub-title '>
            <img className='service-img ' src={service1} alt="service-sub-title 1" />
            <h3>ENGINEERING DESIGN</h3>
            <p>Engineering design is a fundamental aspect of the engineering process, encompassing the creation and development of products, systems, or structures to meet specific requirements and solve problems efficiently. It involves a systematic approach that integrates scientific principles, mathematical analysis, creativity, and practical considerations to produce innovative and functional solutions.</p>
           </div>
           <div className='service-sub-title'>
            <img className='service-img ' src={service2} alt="service-sub-title 2" />
            <h3>OWNERS ENGINEER</h3>
            <p>An Owner's Engineer, often abbreviated as OE, is a professional or a team of professionals hired by an entity or an individual (the owner) to represent their interests throughout the lifecycle of a project. The role of an Owner's Engineer is multifaceted, spanning various phases from project conception to completion. Their primary objective is to ensure that the owner's interests are protected, and the project is executed successfully, meeting predefined objectives in terms of quality, cost, schedule, and safety. Here are the key responsibilities and functions typically associated with an Owner's Engineer:</p>
           </div>
           <div className='service-sub-title '>
            <img className='service-img ' src={service3} alt="service-sub-title 3" />
            <h3>PROJECT MANAGEMENT</h3>
            <p>Project management is the process of planning, organizing, executing, controlling, and closing projects efficiently and effectively to achieve specific goals and objectives within defined constraints such as time, cost, scope, and quality. It is a critical discipline applied across various industries and sectors to successfully deliver projects of varying complexity and scale. Here are the key components and processes involved in project management</p>
           </div>
           <div className='service-sub-title '>
            <img className='service-img '  src={service4} alt="service-sub-title  4" />
            <h3>CONSTRUCTION MANAGEMENT</h3>
            <p>Construction management is the practice of overseeing the planning, design, and construction of a project from inception to completion. It involves coordinating various stakeholders, resources, and activities to ensure that the project is delivered on time, within budget, and according to quality standards and specifications. Construction managers play a crucial role in facilitating communication, managing risks, and resolving issues to ensure the successful execution of construction projects</p>
           </div>
           <div className='service-sub-title '>
            <img className='service-img '  src={service5} alt="service-sub-title 5" />
            <h3>TESTING AND COMMISSIONING</h3>
            <p>Testing and commissioning are crucial phases in the lifecycle of a project, especially in industries such as construction, engineering, manufacturing, and infrastructure development. These phases ensure that systems, equipment, or facilities are operational, compliant with standards, and ready for use.</p>
           </div>
           <div className='service-sub-title '>
            <img className='service-img ' src={service6} alt="service-sub-title 6" />
            <h3>TECHNICAL SERVICES</h3>
            <p>Technical services encompass a broad range of specialized services provided by professionals with expertise in various technical fields. These services are typically offered to industries, businesses, governments, and individuals to support their operations, projects, and initiatives. Technical services play a crucial role in facilitating innovation, problem-solving, and decision-making across different sectors. </p>
           </div>
           <div className='service-sub-title '>
            <img className='service-img ' src={service7} alt="service-sub-title 6" />
            <h3>SPECIALIZED SERVICES</h3>
            <p>Specialized services refer to a variety of professional services that require specific expertise, skills, or knowledge in niche areas. These services are often tailored to meet the unique needs and requirements of clients across various industries. Specialized service providers offer targeted solutions to address specific challenges, tasks, or projects </p>
           </div>
      </Slider>
        </div>
      </div>
      <div className='customer-reviews-section container pt-5'>
      <div className='row p-2'>
       <div className='col-lg-6 col-md-6 col-sm-12 background-review '>
            
        <div className='review-content ' >
            <h2>What our Customer's Say ?</h2>
            <p>K A Projects is a hands on company inside and out. Not only is it important for us to make sure the work environment is enjoyable, but that we provide the best care for all of our clients. We truly loves the work it does and the clients it helps on a daily basis.</p>
        </div>
       </div>
       <div className='col-lg-6 col-md-6 col-sm-12 customer-review-images'>
        <img src={CustomerReviewImage} alt='customer image'/>
       </div>
      </div>
      </div>
        <div className='customer-review'>
          {/* Our International Clients start */}
      <div className='international-clients container p-4'>
      <h2>Our Clients</h2>
      <img src={Line} alt='line' className='service-line2'></img>
      <Slider {...settingsClient} className='pt-2'>
       <div className='international-clients-images'>
           <img src={Client1}  alt="Client 1"></img>
       </div>
       <div className='international-clients-images'>
           <img src={Client2}  alt="Client 2" ></img>
       </div>

       <div className='international-clients-images'>
           <img src={Client4}  alt="Client 3"></img>
       </div>
       <div className='international-clients-images'>
           <img src={Client5}  alt="Client 3"></img>
       </div>
       <div className='international-clients-images'>
           <img src={Client6}  alt="Client 3"></img>
       </div>
       <div className='international-clients-images'>
           <img src={Client7}  alt="Client 3"></img>
       </div>
       <div className='international-clients-images'>
           <img src={Client8}  alt="Client 3"></img>
       </div>
       <div className='international-clients-images'>
           <img src={Client9}  alt="Client 3"></img>
       </div>
       <div className='international-clients-images'>
           <img src={Client10}  alt="Client 3"></img>
       </div>
       <div className='international-clients-images'>
           <img src={Client11}  alt="Client 3"></img>
       </div>
      </Slider>

        </div>
        </div>
        
        
        {/* Our Clients  Section Start */}
        <div className='map container'>
            <h2>Our Clients</h2>
            <img src={Line} alt='line' className='clients-line'></img>
            <div className='row distance'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
              <p>1.	L & T CONSTRUCTION</p>
            <p>2.	L & T AUTOMATION SYSTEM</p>
            <p>3.	TATA PROJECTS</p>
            <p>4.	TATA POWER SOLAR  SYSTEMS LIMITED</p>
            <p>5.	ORIONA SOLAR </p>
            <p>6.	C&S ELECTIRC LTD</p>
            <p>7.	MBH POWER LTD</p>
            <p>8.	BHEL</p>
            <p>9.	OPTCL</p>

              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<img src={OurClients} alt='line' ></img>
</div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
            <p>10.	VOLTAS LIMITED</p>
            <p>11.	SHOBA PROJECTS</p>
            <p>12.	WIND POWER PROJECTS</p>
            <p>13.	JYOTITECH INDUSTRIES</p>
            <p>14.	SIFANG AUTOMATION</p>
            <p>15.	NR ELECTRIC LTD</p>
            <p>16.	Wind World India Ltd</p>
            <p>17.  International Energy Technik LTD</p>

</div>

            </div>
           
           

        </div>
     <Arrow/>
     <Footer/>
        <CopyRightBar/>
    </div>
  );
};

export default Home;
