import React from 'react';
import '../Style/Contact.css'; // Import CSS file for styling
import AddressBar from '../Components/AddressBar';
import Navbar from '../Components/Navbar';
import CopyRightBar from '../Components/CopyRightBar';
import Arrow from '../Components/Arrow';
import Footer from '../Components/Footer';

const Contact = () => {
  const openWhatsAppChat = () => {
    window.open('https://wa.me/1234567890', '_blank'); // Replace 1234567890 with your WhatsApp number
  };
    const handleSubmit = (event) => {
      event.preventDefault(); // Prevent default form submission
      // You can perform additional validation or submit the form data here
    };
  
    return (
      <div>
        {/* Address Bar */}
        <AddressBar/>
        {/* Navbar */}
        <Navbar/>
        {/* Header Section */}
        <div className="about-header">
          <div className="header-content">
            <h1>Contact Us</h1>
          </div>
        </div>
        {/* Contact Information */}
        <div className="contact-information p-3">
          <div className='container'>
          <h2>Contact Information</h2>
          <p><strong>Head Office:</strong><br />
            No.69,3rd Main Road,Kalyan Nagar,<br />Moodalapalya Road,Nagarbhavi PO,<br />
            Bengaluru-560072<br />
            Karnataka,India.
            <br />
            Cell:9353990234</p>
            <p>Email Id : vanitha.satish@kaprojects.co.in</p>
            <br />
            
          <p><strong>Branch Office:</strong><br />
          No.45,Ishwarya Nagar,PWD Extension,<br />Mathakootai Road,Thulukkampatti PO,<br />
            Thanjavur-613005<br />
            Tamil Nadu,India.
            <br />
            Cell:9159037755  </p>
            <p>Email Id : prabika.p@kaprojects.co.in</p>
          </div>
        </div>
        {/* Career Query Form */}
        <div className='p-4'>
        <div className="career-form">
          <h2>Send us your query</h2>
          <form onSubmit={handleSubmit}>
            {/* Full Name */}
            <input type="text" placeholder="Full Name" required />
            {/* Email Address */}
            <input type="email" placeholder="Email Address" required />
            {/* Phone Number */}
            <input type="tel" placeholder="Phone Number" required />
            {/* Title / Subject */}
            <input type="text" placeholder="Title / Subject" required />
            {/* Detailed Message */}
            <textarea placeholder="Detailed Message" required />
            {/* Upload Files */}
            <input type="file" accept=".jpg,.png,.gif,.pdf,.doc,.docx" />
            {/* Submit Button */}
            <button type="submit">Submit</button>
          </form>
        </div>
        </div>
        {/* Copy Rights */}
        <Footer/>
        <CopyRightBar/>
            {/* WhatsApp icon */}
         <Arrow/>
      </div>
    );
  }
  
  export default Contact;
