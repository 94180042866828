import React from 'react';
import '../Style/Ongoing.css'; 
import AddressBar from '../Components/AddressBar';
import Navbar from '../Components/Navbar';
import CopyRightBar from '../Components/CopyRightBar';
import Whatsup from '../assests/images/whatsup.png'; 
import Arrow from '../Components/Arrow';
import Footer from '../Components/Footer';
import Line from '../assests/images/line.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

const OngoingPropjects = () => {
  const openWhatsAppChat = () => {
    window.open('https://wa.me/1234567890', '_blank'); // Replace 1234567890 with your WhatsApp number
  };

  return (
    <div>
      {/* Address Bar */}
      <AddressBar />
      {/* Navbar */}
      <Navbar />

      <div className="ongoing-header">
        <div className="header-content">
          <h1>Ongoing Projects</h1>
        </div>
      </div>

      {/* Services Section */}
      <div className="container service">
        <h2>Major Ongoing Projects</h2>
        <img src={Line} alt="line" className="clients-line" />

        {/* Service Details in List Format */}
        <div className="projects-list">
          <ul>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of 225kV/90 kV, Substation Station</h3>
                <p>Location: Ngosoe at Cameroon</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of 330/132/33 kV Substation</h3>
                <p>Location: New BAUCHI, Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>

              </div>
              <div className="project-info">
                <h3>Siemens make relays configuration and testing at 1*660 MW thermal power plant, Panki, Kanpur-C&S Electric ltd</h3>
                <p>Location: Kanpur</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of 330/132/33 kV Substation at Maduguri Substation</h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
            <li>
              <div className="icon-style">
              <i className="fas fa-project-diagram"></i>
              </div>
              <div className="project-info">
                <h3>Testing and Commissioning of 132/33 kV AJAH mobile Substation </h3>
                <p>Location: Nigeria</p>
              </div>
            </li>
           

          </ul>
        </div>
      </div>

      {/* Footer */}
      <Footer />
      <CopyRightBar />

      {/* WhatsApp Icon */}
      <div className="whatsapp-icon" onClick={openWhatsAppChat}>
        <img src={Whatsup} alt="WhatsApp Icon" className="whatsapp-icon-img" />
      </div>

      {/* Arrow Icon */}
      <Arrow />
    </div>
  );
};

export default OngoingPropjects;
