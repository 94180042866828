import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Style/Navbar.css';
import Logo from '../assests/images/Logo2.jpg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For dropdown

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <nav className="navbar navbar-fixed">
        <div className="navbar-container container">
          <div className="navbar-logo">
            <div className='logo d-flex'>
              <div>
                <img src={Logo} alt="Logo" />
              </div>
              <div className='pt-3'>
                <span className="company-name">K A PROJECTS<br/></span>
                <p>Power Pros</p> 
              </div>
            </div> 
          </div>
          <div className={`navbar-links ${isOpen ? "active" : ""}`}>
            <a href="#"><Link to='/'>Home</Link></a>
            <a href="#"><Link to='/about'>About Us</Link></a>
            {/* Dropdown for Projects */}
            <div className="dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
              <a href="#" className="dropdown-toggle">
                <Link to=''>Projects</Link>
              </a>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <Link to="/domestic-projects">Domestic Projects</Link>
                  <Link to="/international-projects">International Projects</Link>
                  <Link to="/ongoing-projects">Ongoing Projects</Link>
                </div>
              )}
            </div>
            <a href="#"><Link to='/service'>Services</Link></a>
            <a href="#"><Link to='/career'>Career</Link></a>
            <a href="#"><Link to='/contact'>Contact Us</Link></a>
          </div>
          <div className="navbar-toggle" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
